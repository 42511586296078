import { TGameListItem, getGameList } from '@/apis/hub88';
import { fetchGeoInfo } from '@/helper/fetchGeoInfo';
import { gameListSelector } from '@/state/globalSettings';
import { userLoginGeoInfoState } from '@/state/userInfo';
import { useRequest } from 'ahooks';
import { useAtom, useSetAtom } from 'jotai';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import useDeviceInfo from '../useDeviceInfo';
import { isServer } from '@/configs';
import useModalControlFromQueryString from './useModalControlFromUrlQuery';

import { JavaAPIResponse } from '@/helper/http/fetch';
import { store } from '@/state/store';
import useCurrencyRate from './useCurrencyRateTable';

const useAppMounted = () => {
  const setGeoInfo = useSetAtom(userLoginGeoInfoState, { store: store });
  const [{ list }, updateGameList] = useAtom(gameListSelector, {
    store: store,
  });

  const { query } = useRouter();

  /**
   * fetch game list
   */
  const { runAsync: fetchGameListOnMount } = useRequest<
    TGameListItem[],
    undefined[]
  >(() => getGameList(!!query['prod-list']), {
    onSuccess: (data) => {
      console.log('%c~LOG~', 'color: yellow; font-size: 16px;', 'success!!!');
      if (data.length) {
        updateGameList(data);
      }
    },
    ready: !list.length && !isServer,
    // manual: true,
  });
  useEffect(() => {
    fetchGeoInfo(false).then((data) => {
      setGeoInfo({
        country: data?.countryCode,
        ip: data?.ip,
        countryName: data?.countryName,
      });
    });
  }, []);
  useModalControlFromQueryString();
  useCurrencyRate();
};

export default useAppMounted;
